<template>
	<div class="mainTem">
		<el-form ref="form" inline :rules="formRules" :model="form" label-width="100px" style="min-width: 1100px;width: 100%;" v-loading="loading">
			
			<!-- <el-tabs type="border-card"> -->
			  <!-- <el-tab-pane :label="$t('i18nn_3930df65e31d907b')"> -->
					<el-card>
						<div slot="header">
							<h3>
								<!-- {{$t('i18nn_3930df65e31d907b')}} -->
								<TipsActionHotTable></TipsActionHotTable>
							</h3>
						</div>
						<div>
							<el-form-item :label="$t('i18nn_a0f2924544ff3ad8')" required>
								<!-- <el-select filterable clearable v-model="form.whNo" :placeholder="$t('FormMsg.Please_select')" style="width: 300px;">
									<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
				<span class="sel_option_code" style="">{{ item.code }}</span>
			</div></el-option>
								</el-select> -->
								<whNoSelect ref="whNoSelect" size="" @changeData="changWhNo"></whNoSelect>
							</el-form-item>
							
							<el-form-item :label="$t('i18nn_3601b5a98803f4d7')" prop="" required>
								<el-select filterable clearable v-model="form.isSelfLabel" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
									<el-option v-for="item in selectOption.biz_wh_is_shelf_label" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item prop="">
								<span slot="label">
									<span>{{$t('i18nn_fc554f12d8734681')}}</span>SKU<span>{{$t('i18nn_f085596674018f3e')}}</span>
								</span>
								<el-switch v-model="form.relationFlag" :active-value="'1'" :inactive-value="'0'"
									:active-text="$t('i18nn_02ccd2cf723f9272')" :inactive-text="$t('i18nn_e76d885ae1c74d4f')">
								</el-switch>
							</el-form-item>
							
						<el-form-item :label="'模版格式'" prop="">
							<el-select filterable clearable v-model="form.tmp" :placeholder="$t('i18nn_2ad108ab2c560530')"
								style="width: 200px;">
								<el-option :label="'默认(通用)'" :value="null"></el-option>
								<el-option :label="'易仓邮寄模版'" :value="'YICANGYOUJI'"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item v-if="'YICANGYOUJI'==form.tmp">
							<el-link type="primary" target="_blank"
								:href="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/modelv2/YC%E9%82%AE%E5%AF%84%E6%A8%A1%E6%9D%BF.xlsx'+'?random=' + Math.random()"
								icon="el-icon-download">
								易仓模版参考
							</el-link>
						</el-form-item>
						<!-- </div> -->
					<!-- </el-card> -->
				<!-- <el-card> -->
					<!-- <span v-if="hasEdit" class="red">编辑时，显示数据仅供参考，建议重新上传模板</span> -->
					<dropShippingImport ref="dropShippingImport" @parsingSuccess="parsingSuccess" :excelDataDto="excelDataDto" :templateType="form.tmp"></dropShippingImport>
					</div>
				</el-card>
				<!-- </el-tab-pane> -->
			  <!-- <el-tab-pane :label="$t('i18nn_032abee95f72da18')">
					<el-card>
						<div slot="header">
							<span>{{ $t('Storage.packing_list.basic_information') }}</span>
						</div>
						<div>
							<el-form-item :label="$t('i18nn_7431e76029678ec7')" prop="">
								<el-input type="text" v-model="form.title" :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
							</el-form-item>
					
							<el-form-item :label="$t('i18nn_3e358d140504ab46')" prop="detail" class="">
								<HyQuillEditor ref="myTextEditor" :value="form.contentStr" @editorChange="onEditorChange" class=""></HyQuillEditor>
							</el-form-item>
						</div>
					</el-card>
					
					<el-card>
						<div slot="header">
							<span>{{ $t('i18nn_8200a25ab641ba97') }}</span>
						</div>
						<div>
							<HyElWhUpLoad
								ref="HyElWhUpLoad"
								:auto_upload="true"
								:listType="''"
								:watchKey="fileWatchKey"
								:fileListShow="fileList"
								:fileId="fileId"
								:folder="'storage/attachment/'"
								:fileKey="'workOrder'"
								:bucket="'hytx-oss-wh'"
								:accept="''"
								:maxSizeMB="50"
								:limit="10"
								v-on:UploadSuccessUrl="changeFileUrl"
							></HyElWhUpLoad>
						</div>
					</el-card>
					
					<el-card>
						<div slot="header">
							<span>{{ $t('Storage.packing_list.Other') }}</span>
						</div>
						<div>
							<el-form-item :label="$t('Storage.tableColumn.remark')">
								<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit></el-input>
							</el-form-item>
						</div>
					</el-card>
				</el-tab-pane> -->
			<!-- </el-tabs> -->
			
			
		</el-form>

		<div  class="submit-footer">
			<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{$t('i18nn_514377f02a8481e6')}}</el-button>
			<!-- <span class="red">编辑保存时，显示数据仅供参考，建议重新上传模板</span> -->
		</div>
		<!-- <div style="margin: 10px;" v-else>
			<el-button type="warning" @click="submitForm('form')" style="width: 200px;">{{ $t('FormMsg.Save') }}</el-button>
		</div> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import TipsActionHotTable from '@/components/StorageCenter/workOrder/TipsActionHotTable.vue';
	import dropShippingImport from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingImport.vue';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
// import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';
// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';

export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	// },

	// props: {
	//   // mobile:"",
	//   isSel: {
	//     default: function() {
	//       return false;
	//     },
	//     type: Boolean
	//   },
	//   status: {
	//     default: function() {
	//       return '';
	//     },
	//     type: String
	//   }
	// },
	components: {
		// HyElWhUpLoad,
		// HyQuillEditor,
		TipsActionHotTable,
		dropShippingImport,
		whNoSelect
	},
	data() {
		return {
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			loading: false,
			
			//是否可编辑
			hasEdit:false,
			// loading_det: false,

			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,
			
			excelDataDto:[],

			form: {
				id: '', //"数据ID",
				whNo:'',
				isSelfLabel:"",
				relationFlag:'0',
				tmp:''
				// title: '', //this.$t('7431e76029678ec7'),
				// orderType: '40', //this.$t('i18nn_f566f50ef680920c'),
				// contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				// attachments: [
				// 	// {
				// 		// id: '', //"数据ID",
				// 		// url: '', //"文件URL",
				// 		// fileName: '' //this.$t('i18nn_89221ad15d2ec113')
				// 	// }
				// ]
			},

			formRules: {
				// title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
			},
			// loading_load: false,

			selectOption: {
				wh_work_order_type: [],
				biz_wh_is_shelf_label:[],
				// wh_no: [],
			}
		};
	},
	activated() {
		this.initData();
	},
	//创建时
	created() {
		// this.getPageData();
		// this.initData();
	},
	//编译挂载前
	mounted() {
		
		// this.getDicData();
		//数据字典
		getDicData(['biz_wh_is_shelf_label'],
			(data)=>{
				this.selectOption.biz_wh_is_shelf_label = data['biz_wh_is_shelf_label'];
		});
		
	},
	methods: {
		initData() {
			this.resetForm('form');
			
			if(this.$route.query.id){
				//编辑
				this.hasEdit = true;
				// this.getPageDetData(this.$route.query.id);
			} else {
				//新增
				this.hasEdit = false;
			}
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.form.whNo = data.code;
			// this.filterData.userId = data.userId;
			// this.initData();
		},
		parsingSuccess(data){
			// this.form.expressDto = data;
		},
		// changeAgentUser(val){
		// 	// this.filterData.agentUser = val;
		// 	// this.initData();
		// },
		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		// openDioalog(formParm, msg) {
		// 	// console.log(formParm);
		// 	this.dialogFormVisible = true;
		// 	let form = Object.assign({}, formParm);
		// 	console.log('form', form);
		// 	this.dialogFormMsg = msg;
		// 	// // 重置
		// 	this.resetForm('form');
		// 	if (null === formParm) {
		// 		//新增

		// 		// this.form.weightUnit = '1';
		// 		// this.form.volumeUnit = '1';
		// 		// this.form.whFeeType = '1';

		// 		this.dialogFormStatus = 0;
		// 		// form.subUserId = null;
		// 		// form.userSubUserId = null;
		// 		// form.state = true;
		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	} else {
		// 		//修改
		// 		this.dialogFormStatus = 1;
		// 		// form.state = form.state === '0' ? true : false;

		// 		//浅拷贝、对象属性的合并
		// 		this.form = form;
		// 	}

		// 	this.$forceUpdate();
		// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		// 	//       type: 'warning',
		// 	//       //confirmButtonText: this.$t('204ffab8a6e01870'),
		// 	//     });
		// },
		// 富文本
		// onEditorChange(html) {
		// 	this.form.contentStr = html;
		// },
		//打开编辑
		// openEdit(event, row, index) {
		// 	event.stopPropagation();
		// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
		// 	this.getPageDetData(row.id);
		// },
		//明细
		//请求分页数据
		// getPageDetData(id) {
		// 	// let _this = this;
		// 	this.loading = true;

		// 	this.$http
		// 		.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
		// 		.then(({ data }) => {
		// 			// console.log(this.$store.getters.getUserInfo);
		// 			console.log('分页，请求成功');
		// 			console.log(data);
		// 			
		// 			this.loading = false;
		// 			//是否可编辑
		// 			// if('0'==data.data.status){
		// 			// 	this.hasEdit = true;
		// 			// }
		// 			//表格显示数据
		// 			this.form.id = data.data.id;
		// 			this.form.whNo = data.data.whNo;

		// 			// this.fileList = data.data.attachments.map(item => {
		// 			// 	return {
		// 			// 		id: item.id,
		// 			// 		name: item.fileName,
		// 			// 		url: item.url
		// 			// 	};
		// 			// });
		// 			// this.fileWatchKey = new Date().getTime();
					
		// 			this.excelDataDto = data.data.expressDto;
		// 			this.form.expressDto = this.excelDataDto;
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('分页，请求失败');
		// 			this.loading = false;
		// 		});
		// },

		//上传回调
		// changeFileUrl(val) {
		// 	console.log('changeFileUrl', val);
		// 	let listUrl = val.map(item => {
		// 		// "filePath":"","fileName"：“”
		// 		return {
		// 			url: item.url,
		// 			fileName: item.name
		// 		};
		// 	});

		// 	this.form.attachments = listUrl;
		// },

		//提交信息
		submitForm(formName) {
			// this.$nextTick(() => {
			// 	try {
			// 		this.$refs.HyElWhUpLoad.submitUpload();
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });
			
			this.$nextTick(()=>{
				this.form.expressDtoList = this.$refs.dropShippingImport.getData();
				
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.form);
						//浅拷贝、对象属性的合并
						// if (0 === this.dialogFormStatus) {
						// formData.id = null;
						// formData.userId = this.UserInfo.id;
				
						this.postData(this.$urlConfig.WhDropShippingBatchInsert+'/'+this.$route.query.cusUserId, formData, () => {
							this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
							this.$emit('submitSuccess','');
						});
						// } else {
						// 	formData.userId = this.UserInfo.id;
						// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
						// }
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
				
			})

			
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			this.$nextTick(() => {
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					this.form.relationFlag = '0';
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			});
			this.$nextTick(() => {
				try {
					this.$refs.whNoSelect.init('');
					this.$refs.dropShippingImport.initData();
				} catch (err) {
					console.log(err);
				}
			});
		},

		//提交信息
		postData(url, formData, callback) {
			// HttpTypelet _this = this;
			this.loading = true;
			// this.loading_load = true;
			let HttpType = {};
			// if ('delete' == type) {
			// 	HttpType = this.$http.delete(url, formData);
			// } else {
			HttpType = this.$http.put(url, formData);
			// }
			HttpType.then(({ data }) => {
				console.log(this.$t('i18nn_bc868e024b80d2e3'));
				console.log(data);
				
				this.loading = false;
				// this.loading_load = false;
				if (200 == data.code) {
					// this.dialogFormVisible = false;
					// this.getPageData();

					// if ('delete' == type) {
					// 	this.$message({
					// 		type: 'success',
					// 		message: this.$t('tips.successfullyDelete')
					// 	});
					// } else {
					this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						type: 'success'
					});
					callback();
					// }
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				// this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: this.$t('204ffab8a6e01870'),
				});
			});
		},

		//查询数据字典
		// getDicData() {
		// 	// this.loading_load = true;
		// 		this.$http
		// 			.put(this.$urlConfig.HyDicQueryList, ['biz_wh_is_shelf_label'])
		// 			.then(({ data }) => {
		// 				console.log('查询数据字典，请求成功');
		// 				console.log(data);
		// 				if (200 == data.code && data.data) {
		// 					// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 					// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 					// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 					this.selectOption.biz_wh_is_shelf_label = data.data['biz_wh_is_shelf_label'];
		// 					// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 				} else {
		// 					if (!data.msg) {
		// 						data.msg = this.$t('tips.submitError');
		// 					}
		// 					this.$message.warning(data.msg);
		// 				}
		// 			})
		// 			.catch(error => {
		// 				console.log(error);
		// 				console.log('查询数据字典接口，请求失败');
		// 				this.$message.error(this.$t('tips.submitRequestError'));
		// 			});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
